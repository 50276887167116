import styled from 'styled-components';
import { Flair, FooterMenu, Hero, ContactAt } from '@mii/ui';

/* eslint-disable-next-line */
export interface MiiFeatureLegalProps {}

const StyledMiiFeatureLegal = styled.div`
  color: '#0a0a0a';
  font-family: 'Roboto Slab';
`;

export function MiiFeatureLegal(props: MiiFeatureLegalProps) {
  return (
    <StyledMiiFeatureLegal className="container flex flex-col py-5 items-center">
      <Hero />
      <div className="w-full p-4">
        <p className="text-1xl md:text-2xl lg:text-3xl pb-5">
          <b>Anbieter dieser Internetseite ist:</b>
        </p>
        <address>
          <p className="pb-5 md:text-lg lg:text-xl">
            Diana Siegmund
            <br />
            Im Altenhof 4<br />
            65510 Idstein
            <br />
            <br />
            <a href="mailto:kontakt@madeinidstein.de">
              kontakt@madeinidstein.de
            </a>
          </p>
        </address>
        <p className="pb-5 text-sm md:text-base lg:text-lg font-light">
          Inhaltlich Verantwortliche gem&auml;ß § 18 Abs. 2 MStV:
          <br />
          Diana Siegmund
        </p>
      </div>
      <Flair />
      <ContactAt mail="kontakt@madeinidstein.de" />
      <Flair />
      <FooterMenu />
    </StyledMiiFeatureLegal>
  );
}

export default MiiFeatureLegal;
