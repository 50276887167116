import { Route, Switch } from 'react-router-dom';
import { MiiFeatureHome } from '@mii/mii/feature-home';
import { MiiFeatureLegal } from '@mii/mii/feature-legal';
import { MiiFeaturePrivacy } from '@mii/mii/feature-privacy';
import { MiiFeature404 } from '@mii/mii/feature-404';

import './styles.css';

export function App() {
  return (
    <Switch>
      <Route exact path="/" component={MiiFeatureHome} />
      <Route path="/feature-privacy" component={MiiFeaturePrivacy} />
      <Route exact path="/legal" component={MiiFeatureLegal} />
      <Route component={MiiFeature404} />
    </Switch>
  );
}

export default App;
