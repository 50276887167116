import { useState, useEffect } from 'react';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

import InstagramPlaceholder from '@mii/assets/images/instagram_placeholder_400.png';

export interface InstagramFeedProps {
  token: string;
  limit: number;
}

const StyledInstagramFeed = styled.div``;

export function InstagramFeed(props: InstagramFeedProps) {
  const { token, limit } = props;
  const [feeds, setFeedsData] = useState([]);

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&limit=${limit}&access_token=${token}`
          )
          .then((resp) => {
            console.log(resp)
            setFeedsData(resp.data.data);
          });
      } catch (err) {
        console.log('error', err);
      }
    }
    // manually call the fecth function
    fetchInstagramPost();

    async function fetchApi() {
      try {
        axios.get(`/api`).then((resp) => {
          console.log(resp);
        });
      } catch (err) {
        console.log('error', err);
      }
    }
    fetchApi();

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, [limit, token]);

  return (
    <StyledInstagramFeed className="py-4 md:px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 px-5">
        {feeds.map((feed) => {
          const { id, caption, media_type, media_url, permalink } = feed;
          if (media_type !== 'VIDEO') {
            return (
              <a key={id} href={permalink} target="_blank" rel="noreferrer">
                <LazyLoadImage
                  className="filter sepia-0 hover:sepia"
                  width="100%"
                  height="auto"
                  id={id}
                  src={media_url}
                  alt={caption}
                  effect="opacity"
                  placeholderSrc={InstagramPlaceholder}
                />
              </a>
            );
          }
          return false;
        })}
      </div>
    </StyledInstagramFeed>
  );
}

export default InstagramFeed;
