import React from 'react';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface FaqsProps {}

const StyledFaqs = styled.div`
  width: 100%;
  p:nth-child(1) {
    border-top: 0.5rem solid #000000;
  }
`;

const faqs = [
  {
    id: 1,
    title: 'Wo ist der Onlineshop?',
    content:
      'Auf dieser Seite siehst Du Anfertigungen nach Kundenwunsch und handgefertigte Designobjekte in kleiner Auflage. Einige Einzelstücke sowie die Idsteiner Täubchen findet Ihr aktuell exklusiv im Idsteiner Tabakum.',
  },
  {
    id: 2,
    title: 'Findet man dich auf Kunstmärkten oder so?',
    content:
      'Definitiv; also gerne bald wieder! Hier würde das Datum stehen, wenn es bereits Pläne gäbe... Bist Du Veranstalter von Kreativmärkten und willst noch eine Lücke füllen? - Anfragen gerne ebenfalls per Mail an: kontakt@madeinidstein.de',
  },
  {
    id: 3,
    title: 'Kann ich gewerblich oder für größere Projekte anfragen?',
    content:
      'Gerne! Für gewerbliche Anfragen, individuelle Auftragsarbeiten oder Anfragen nach größeren Auflagen bestimmter Arbeiten, schreib einfach eine Mail an: kontakt@madeinidstein.de, bestenfalls schon mit weiteren Informationen. Aktuell besteht aus organisatorischen Gründen leider nicht die Möglichkeit zum Bestellen und zum Versenden einzelner Tauben an Privatpersonen, sorry.    =/',
  },
];

export function Faqs(props: FaqsProps) {
  return (
    <StyledFaqs className="px-5 py-9">
      <h1 className="text-4xl text-justify sm:text-left xl:text-center font-bold">
        FAQs
      </h1>
      {faqs.map((faq) => {
        const { id, title, content } = faq;
        return (
          <div key={id} className="py-5">
            <p className="text-2xl sm:text-3xl py-5 font-bold">{title}</p>
            <p className="text-2xl sm:text-3xl py-5">{content}</p>
          </div>
        );
      })}
    </StyledFaqs>
  );
}

export default Faqs;
