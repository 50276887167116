import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

/* eslint-disable-next-line */
export interface FooterMenuProps {}

const StyledFooterMenu = styled.div``;

export function FooterMenu(props: FooterMenuProps) {
  return (
    <StyledFooterMenu className="text-lg lg:text-xl xl:text-2xl">
      <NavLink exact className="px-5" to="/" activeClassName="underline">
        Home
      </NavLink>
      <NavLink className="px-5" to="/legal" activeClassName="underline">
        Impressum
      </NavLink>
    </StyledFooterMenu>
  );
}

export default FooterMenu;
