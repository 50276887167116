import styled from 'styled-components';
import Logo from '@mii/assets/images/logo.png';

/* eslint-disable-next-line */
export interface HeroProps {}
const StyledHero = styled.div``;

export function Hero(props: HeroProps) {
  return (
    <StyledHero className="p-4">
      <img src={Logo} alt="Logo" />
    </StyledHero>
  );
}

export default Hero;
