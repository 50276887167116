import styled from 'styled-components';
import FlairBreak from '@mii/assets/images/flair.png';

/* eslint-disable-next-line */
export interface FlairProps {}
const StyledFlair = styled.div``;

export function Flair(props: FlairProps) {
  return (
    <StyledFlair className="px-4 py-6">
      <img src={FlairBreak} alt="Flair" />
    </StyledFlair>
  );
}

export default Flair;
