import styled from 'styled-components';

import {
  ContactAt,
  Faqs,
  Flair,
  FooterMenu,
  Hero,
  InstagramFeed,
} from '@mii/ui';

/* eslint-disable-next-line */
export interface MiiFeatureHomeProps {}

const StyledMiiFeatureHome = styled.div`
  color: '#0a0a0a';
  font-family: 'Roboto Slab';
`;

export function MiiFeatureHome(props: MiiFeatureHomeProps) {
  return (
    <StyledMiiFeatureHome className="container flex flex-col py-5 items-center">
      <Hero />
      <InstagramFeed
        limit={36}
        token="IGQVJWd09STXN0TXEzSms1TlNNbEgzeFBvZADZAPRGhVNnhaUXJMNFBzX2lCbXF3cG9KOXFBazg4a2VYTGZAUUTc3djRkUnBiU3V0cXROaWg2QUtxd2R2LTB1TEdzTndxR3BNSVByamdR"
      />
      <Flair />
      <Faqs />
      <Flair />
      <ContactAt mail="kontakt@madeinidstein.de" />
      <Flair />
      <FooterMenu />
    </StyledMiiFeatureHome>
  );
}

export default MiiFeatureHome;
