import styled from 'styled-components';

/* eslint-disable-next-line */
export interface ContactAtProps {
  mail: string;
}

const StyledContactAt = styled.div``;

export function ContactAt(props: ContactAtProps) {
  const { mail } = props;
  return (
    <StyledContactAt className="flex flex-col text-center text-2xl sm:text-3xl md:text-5xl py-9">
      <a className="cursor-pointer" href={`mailto:${mail}`}>
        {mail}
      </a>
    </StyledContactAt>
  );
}

export default ContactAt;
